import { Box, Flex } from "@chakra-ui/react";
import { useEffect } from "react";
import Footer from "../home/footer";
import Navbar from "../home/navbar";

export default function RefundPolicy() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <>
      <Navbar />
      <Flex py={20} px={10} justifyContent={"center"}>
        <Box maxW={"5xl"}>
          <div id="refund-policy">
            <h3 style={{ textAlign: "center", fontSize: "32px", fontWeight: "bold", padding: "10px" }}>REFUND POLICY</h3>
            <p>
              <em>Last Updated: February 5, 2025</em>
            </p>
            <p>Thank you for choosing Brandzway's services. We strive to ensure your satisfaction. However, if you are not completely satisfied, the following policy outlines our guidelines for cancellations and refund requests.</p>

            <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>1. Free Trial</h2>
            <ul>
              <li>
                <strong>14-Day Free Trial:</strong> New users are entitled to a 14-day free trial to experience our services without charge.
              </li>
              <li>
                <strong>Cancellation During Free Trial:</strong> You may cancel your subscription at any time during the free trial period without incurring any charges.
              </li>
            </ul>

            <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>2. Cancellation Policy</h2>
            <ul>
              <li>
                <strong>Ongoing Subscription:</strong> You may cancel your recurring subscription at any time. Upon cancellation, your account will remain active until the end of your current billing cycle.
              </li>
              <li>
                <strong>Self-Service or Support:</strong> Cancellation can be completed through your account dashboard or by contacting our support team.
              </li>
            </ul>

            <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>3. Refund Eligibility</h2>
            <ul>
              <li>
                <strong>Request Timeline:</strong> To be eligible for a refund, you must submit your request within 14 days of your subscription start date.
              </li>
              <li>
                <strong>Conditions for Refunds:</strong> Refunds will be considered on a case-by-case basis and are granted at the sole discretion of Brandzway. Refund requests may be approved if you encounter technical issues that prevent you from using our service—provided these issues cannot be resolved by our support team and you can provide sufficient proof.
              </li>
              <li>
                <strong>Non-Refundable Circumstances:</strong> Refunds will not be granted for:
                <ul>
                  <li>Issues beyond Brandzway's control (e.g., changes in personal circumstances, third-party hardware/software failures).</li>
                  <li>Situations where the refund eligibility period has expired.</li>
                  <li>Billing errors due to user oversight, such as failing to cancel before the renewal date.</li>
                </ul>
              </li>
            </ul>

            <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>4. Process for Requesting a Refund</h2>
            <ul>
              <li>
                <strong>How to Request:</strong> To request a refund, please email our customer support team at <a href="mailto:support@Brandzway.com">support@Brandzway.com</a> with the following details:
                <ul>
                  <li>Your account information and subscription details.</li>
                  <li>A brief explanation outlining the reason for your refund request.</li>
                </ul>
              </li>
              <li>
                <strong>Evaluation:</strong> Upon receiving your request, our team will review the details and contact you with a decision.
              </li>
            </ul>

            <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>5. Refund Processing</h2>
            <ul>
              <li>
                <strong>Approval Notification:</strong> Once your refund request is approved, you will receive an email confirmation.
              </li>
              <li>
                <strong>Processing Time:</strong> Refunds will be processed and credited to the original method of payment within a specified number of business days.
              </li>
              <li>
                <strong>Payment Method:</strong> Refunds can only be issued to the original payment method used at the time of purchase.
              </li>
            </ul>

            <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>6. Amendments to This Policy</h2>
            <p>Brandzway reserves the right to modify this Refund Policy at any time. Any changes will be posted on our website and become effective immediately upon posting. Continued use of our services following any amendments constitutes your acceptance of the updated policy.</p>

            <h2 style={{ marginTop: "20px", marginBottom: "10px" }}>7. Contact Information</h2>
            <p>
              If you have any questions or need further assistance regarding our Refund Policy, please contact us at: <a href="mailto:support@Brandzway.com">support@Brandzway.com</a>
            </p>

            <h3 style={{ marginTop: "20px", marginBottom: "10px" }}>Additional Scenarios</h3>

            <h4>When Refunds Would Typically Be Granted:</h4>
            <ul>
              <li>
                <strong>Billing Error:</strong> If you were incorrectly charged (e.g., double billing or charges made after proper cancellation).
              </li>
              <li>
                <strong>Misrepresentation of Features:</strong> If the SaaS product’s features or capabilities were misrepresented during the sales process or on our website, resulting in a product that does not perform as advertised.
              </li>
            </ul>

            <h4 style={{ marginTop: "20px", marginBottom: "10px" }}>When Refunds Would Not Typically Be Granted:</h4>
            <ul>
              <li>
                <strong>Change of Mind:</strong> If you decide that you no longer need the SaaS product after the refund eligibility period has passed.
              </li>
              <li>
                <strong>Failure to Cancel:</strong> If you do not cancel your subscription before the renewal date, resulting in an additional billing cycle.
              </li>
              <li>
                <strong>External Factors:</strong> If you are unable to use the SaaS product due to reasons beyond Brandzway’s control (e.g., incompatible hardware, poor internet connection, or third-party software issues).
              </li>
            </ul>
          </div>
        </Box>
      </Flex>
      <Footer />
    </>
  );
}
